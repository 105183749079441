import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, navigate } from "gatsby";
import { initializeAuthState, login } from "../store/auth";

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required("Email is required."),
    password: yup.string().required("Password is required."),
  })
  .required();

const LoginPage = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const token = useSelector((state) => state.auth.token);
  const authLoading = useSelector((state) => state.auth.loading);
  const authErrors = useSelector((state) => state.auth.errors);
  const [redirecting, setRedirecting] = useState(false)

  useEffect(() => {
    if (token) {
      setRedirecting(true)
      const searchParams = new URLSearchParams(window.location.search)
      let url = `${process.env.GATSBY_CMS_URL}/headless/sso?token=${token}`
      if (searchParams.has('backUrl')) {
        url = `${process.env.GATSBY_CMS_URL}/headless/sso?token=${token}&returnUrl=${window.location.origin}${decodeURIComponent(searchParams.get('backUrl'))}`
      }
      window.location.href = url
    }
  }, [token]);

  useEffect(() => {
    dispatch(initializeAuthState())
  }, [dispatch, initializeAuthState])

  const onSubmit = useCallback(
    (data) => {
      dispatch(login(data));
    },
    [dispatch]
  );

  return (
    <Layout>
      <div className="login-page">
        <Container>
          <Row className="justify-content-center">
            <Col
              xs={12}
              sm={10}
              md={8}
              lg={7}
              xl={6}
              xxl={5}
              className="d-flex flex-column align-items-center"
            >
              <h1 className="h2">Login</h1>
              <form onSubmit={handleSubmit(onSubmit)} className="w-100">
                <Form.Group className="form-group" controlId="formLoginEmail">
                  <Form.Label className="text-sm">Email Address *</Form.Label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter email"
                    {...register("email")}
                  />
                  {!!errors.email && (
                    <p className="text-danger small mt-1">
                      {errors.email.message}
                    </p>
                  )}
                </Form.Group>

                <Form.Group
                  className="form-group"
                  controlId="formLoginPassword"
                >
                  <Form.Label className="text-sm">Password</Form.Label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Enter password"
                    {...register("password")}
                  />
                  {!!errors.password && (
                    <p className="text-danger small mt-1">
                      {errors.password.message}
                    </p>
                  )}
                </Form.Group>
                {!!authErrors && authErrors.length > 0 && (
                  <div className="group-control">
                    {authErrors.map((er) => (
                      <p key={er} className="text-danger small">
                        {er}
                      </p>
                    ))}
                  </div>
                )}
                <Button
                  variant="outline-primary"
                  className="w-100"
                  type="submit"
                >
                  {redirecting ? 'Redirecting...' : (authLoading ? "Signing in" : "Sign In")}
                </Button>
              </form>

              <a href="https://shop.grocorp.net.au/customer/account/forgotpassword/" className="mb-3">
                Forgot your password?
              </a>
              <p className="mb-4">
                Don't have an account? <Link to="https://shop.grocorp.net.au/customer/account/create/">Register Now</Link>
              </p>
              <Link to="/" className="return">
                Return home
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default LoginPage;
